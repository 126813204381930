<template>
    <div class="w-full">
        <div class="flex flex-row items-center items-between justify-between text-xs font-medium uppercase tracking-wider p-1">
            <p v-if="fullRow.com_remaining_days > 0" class="hidden sm:flex truncate">Quedan {{ fullRow.com_remaining_days }} días</p>
            <p v-else-if="fullRow.com_remaining_days === 0" class="hidden sm:flex truncate">Finalizado</p>
            <div class="flex flex-row">
                <p class="truncate">{{ fullRow.com_progress_percent | numberFormat(0) }}%</p>
                <p class="truncate bg-main px-1 ml-1 rounded-sm text-white"> T{{ fullRow.com_progress_step }}/5</p>
            </div>
        </div>
        <div class="xl:px-2">
            <div class="flex flex-row justify-start bg-bo-total rounded-lg shadow-lg">
                <div class="h-2 rounded-lg bg-bo-active" :style="'width: '+ fullRow.com_progress_percent +'%'"></div>
            </div>
        </div>
        <div class="flex flex-row items-center justify-center text-xs font-medium uppercase tracking-wider">
            <p class="truncate">{{ fullRow.com_start_date | middleDateFormat }} - {{ fullRow.com_end_date | middleDateFormat }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "CellComProgressInfo",
    props: ['fullRow'],
    data() {
        return {
            tooltip: false
        }
    }
}
</script>

<style scoped>

</style>