<template>
    <All model="HpList" :immediate="true" v-slot="{data:hpLists}" :query="hpListsFilter"
         @loading:change="(event) => loading = event" ref="hpListRef">
        <div class="flex flex-col w-full justify-center items-end text-bo-input">
            <div class="flex flex-row w-full justify-end">
                <searcher class="w-full md:w-1/3" @changeText="changeText"/>
            </div>
            <HpListSubTable
                :data="hpLists.data" :fields="fields" :loading="loading" :page="hpListsFilter.page"
                @selectRow="selectRow" class="mt-2">
                <template v-slot:size="{value}">
                    <div class="flex justify-center items-center">
                        <p v-if="value" class="py-4 px-2 truncate">{{ value }} contactos</p>
                    </div>
                </template>
            </HpListSubTable>
            <div class="w-full flex flex-row flex-wrap justify-end items-center pt-1">
                <PaginateTextInfo v-if="hpLists.meta" :meta="hpLists.meta" class="flex-auto text-center"/>
                <PaginateLinks v-if="hpLists.meta" :meta="hpLists.meta" @changePage="changePage" class="hidden md:flex"/>
                <PaginateMobile v-if="hpLists.links" :links="hpLists.links" @changePage="changePage" class="md:hidden"/>
            </div>
        </div>
    </All>
</template>

<script>
import All from "@/api/components/All";
import HpListSubTable from "@/pages/inside/shared/datatables/HpListSubTable";
import PaginateLinks from "@/pages/inside/shared/datatables/PaginateLinks";
import Searcher from "@/pages/inside/shared/datatables/Searcher";
import PaginateMobile from "@/pages/inside/shared/datatables/PaginateMobile";
import PaginateTextInfo from "@/pages/inside/shared/datatables/PaginateTextInfo";
export default {
    name: "HpListSelector",
    props: ['prefix', 'target'],
    components: {
        All, HpListSubTable, PaginateLinks, Searcher, PaginateMobile, PaginateTextInfo
    },
    data() {
        return {
            loading: false,
            hpListsFilter: {
                page: null,
                perPage: 10,
                field: 'name',
                direction: 'asc',
                text: null
            },
            el: null,
            fields,
        }
    },
    methods: {
        changePage(page){
            this.hpListsFilter.page = page;
            this.$refs.hpListRef.request(); // Para hacer la request
        },
        changeText(text){
            this.hpListsFilter.page = null;
            this.hpListsFilter.text = text;
            this.$refs.hpListRef.request(); // Para hacer la request
        },
        selectRow(el) {
            this.el = el;
            this.$emit('selection', this.prefix, this.target, this.el);
        },
    }
};

const fields = [
    {
        label: 'Lista HP',
        key: 'name'
    },
    {
        label: 'Contactos',
        key: 'size'
    },
];
</script>

<style scoped>

</style>