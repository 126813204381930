<template>
    <div>
        <button type="button" @click="show = !show" class="p-2 mr-2 w-12 bg-bo-form shadow-md rounded-md focus:outline-none flex justify-center items-center">
            <i class="mdi mdi-eye text-2xl font-semibold leading-none"></i>
        </button>
        <ModalBase :width="'w-95por'" :show="show" @close="show = false">
            <form @submit.prevent="store">
                <Request model="Edition" action="byId" :form="editionFilter" @success="onSuccessGetEdition" @error="onErrorGetEdition" ref="getEditionRef" />
                <div class="flex flex-col overflow-y-scroll xl:overflow-y-hidden h-full">
                    <ModalHeader :type="'show'" :editable="editable" :entity="entity" @close="show = false" />
                    <div class="flex flex-col xl:flex-row overflow-y-scroll xl:overflow-y-hidden">
                        <div class="flex flex-col w-full xl:w-1/3 justify-start items-center p-4 xl:overflow-y-auto h-auto xl:h-100 xl:border-r xl:border-gray-900">
                            <div class="flex flex-col xl:flex-row w-full">
                                <div class="mt-4 w-full">
                                    <span>Curso</span>
                                    <input v-model="form.course_id" class="text-bo-input hidden" />
                                    <div class="mt-1 flex flex-row form-input cursor-pointer p-0 h-10 border-none">
                                        <div class="w-full text-left px-2 focus:outline-none truncate flex items-center" :class="form.course_id ? 'text-bo-input' : 'text-gray-500'" @click="selectorToggle('courses')" ref="coursesNameRef">
                                            {{ selectOptionMsg }}
                                        </div>
                                        <button type="button" @click="clear('courses', 'course_id')" class="focus:outline-none bg-gray-500 hover:bg-gray-300 border text-bo-input rounded-r-md w-10">
                                            <i class="mdi mdi-close text-xl"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <transition name="slide-fade">
                                <div v-if="coursesSelectorExpanded" class="flex flex-row w-full p-2 mt-1 bg-gray-300 rounded rounded-md shadow-md">
                                    <CourseSelector @selection="selection" :prefix="'courses'" :target="'course_id'"></CourseSelector>
                                </div>
                            </transition>
                            <div class="flex flex-col w-full">
                                <div class="flex flex-col w-full w-full mt-4">
                                    <span class="w-full text-left">Comercialización de la edición</span>
                                    <div class="w-full flex flex-row justify-around">
                                        <div class="relative w-1/2 mt-1">
                                            <div class="absolute flex border border-transparent left-0 top-0 h-full w-10">
                                                <div class="flex items-center justify-center rounded-tl rounded-bl z-10 bg-gray-100 text-gray-600 text-lg h-full w-full">
                                                    <span class="mdi mdi-calendar"></span>
                                                </div>
                                            </div>
                                            <input v-model="comDatesFormatted.start" @click="comCalendarToggle" v-on:keyup.space="comCalendarToggle" type="text" class="form-input w-full text-center text-bo-input p-0 h-10 cursor-pointer noselect" placeholder="Inicio" readonly />
                                        </div>
                                        <div class="relative w-1/2 mt-1 ml-2">
                                            <div class="absolute flex border border-transparent left-0 top-0 h-full w-10">
                                                <div class="flex items-center justify-center rounded-tl rounded-bl z-10 bg-gray-100 text-gray-600 text-lg h-full w-full">
                                                    <span class="mdi mdi-calendar"></span>
                                                </div>
                                            </div>
                                            <input v-model="comDatesFormatted.end" @click="comCalendarToggle" v-on:keyup.space="comCalendarToggle" type="text" class="form-input w-full text-center text-bo-input p-0 h-10 cursor-pointer" placeholder="Fin" readonly />
                                        </div>
                                    </div>
                                </div>
                                <transition name="slide-fade">
                                    <div v-if="comCalendarExpanded" class="flex flex-row justify-center p-2 z-20">
                                        <date-picker v-model="comDates" is-range />
                                    </div>
                                </transition>
                                <transition enter-class="opacity-0" enter-active-class="ease-out transition-medium" enter-to-class="opacity-100" leave-class="opacity-100" leave-active-class="ease-out transition-medium" leave-to-class="opacity-0">
                                    <div v-show="comCalendarExpanded" class="z-10 fixed inset-0 transition-opacity">
                                        <div @click="comCalendarToggle" class="absolute inset-0 bg-black opacity-50" tabindex="0"></div>
                                    </div>
                                </transition>
                            </div>
                            <div class="flex flex-col xl:flex-row w-full">
                                <label class="mt-4 w-full">
                                    <span>Nombre edición</span>
                                    <input v-model="form.name" class="form-input mt-1 w-full text-center text-bo-input p-0 h-10" placeholder="Nombre" ref="nameRef" :class="{ 'bg-gray-200 noselect': !editable }" />
                                </label>
                            </div>
                            <div v-show="form.area_has_opportunities_plus" class="flex flex-col sm:flex-row w-full">
                                <div class="flex flex-col justify-center items-center w-full sm:w-1/2">
                                    <span class="flex flex-col h-12 justify-end text-center">Valor inicial de la oportunidad plus</span>
                                    <InputNumber :value="form.initial_opportunity_plus_weight" @increase="increaseInitialOpWeight" @decrease="decreaseInitialOpWeight" />
                                </div>
                                <div class="flex flex-col justify-center items-center w-full sm:w-1/2">
                                    <span class="flex flex-col h-12 justify-end text-center">Valor final de la oportunidad plus</span>
                                    <InputNumber :value="form.final_opportunity_plus_weight" @increase="increaseFinalOpWeight" @decrease="decreaseFinalOpWeight" />
                                </div>
                            </div>
                            <div class="flex flex-col xl:flex-row w-full">
                                <label v-show="form.area_has_opportunities_plus" class="w-full lg:w-1/2 xl:mr-2">
                                    <span class="flex flex-col h-12 justify-end text-left xl:text-center">Objetivo de oportunidades plus</span>
                                    <input v-model="form.opportunities_plus_target" type="number" min="0" step="1" class="form-input mt-1 w-full text-center text-bo-input p-0 h-10" :disabled="!editable" :class="{ 'bg-gray-200 noselect': !editable }" placeholder="Contactos" />
                                </label>
                                <label class="w-full lg:w-1/2">
                                    <span class="flex flex-col h-12 justify-end text-left">Objetivo de clientes</span>
                                    <input v-model="form.customers_target" type="number" min="0" step="1" class="form-input mt-1 w-full text-center text-bo-input p-0 h-10" :disabled="!editable" :class="{ 'bg-gray-200 noselect': !editable }" placeholder="Contactos" />
                                </label>
                            </div>
                        </div>
                        <div class="flex flex-col w-full xl:w-2/3 justify-start items-center p-4 xl:overflow-y-auto h-auto xl:h-100">
                            <div class="flex flex-col xl:flex-row w-full">
                                <div class="mt-4 w-full">
                                    <span class="font-normal capitalize">leads</span>
                                    <input v-model="form.leads_hp_list" class="text-bo-input hidden" />
                                    <div class="mt-1 flex flex-row form-input cursor-pointer p-0 h-10 border-none">
                                        <div class="w-full text-left px-2 focus:outline-none truncate flex items-center" :class="form.leads_hp_list ? 'text-bo-input' : 'text-gray-500'" @click="selectorToggle('leads')" ref="leadsNameRef">
                                            {{ selectOptionMsg }}
                                        </div>
                                        <button type="button" @click="clear('leads', 'leads_hp_list')" class="focus:outline-none bg-gray-500 hover:bg-gray-300 border text-bo-input rounded-r-md w-10">
                                            <i class="mdi mdi-close text-xl"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <transition name="slide-fade">
                                <div v-if="leadsSelectorExpanded" class="flex flex-row w-full p-2 mt-1 bg-gray-300 rounded rounded-md shadow-md">
                                    <HpListSelector @selection="selection" :prefix="'leads'" :target="'leads_hp_list'"></HpListSelector>
                                </div>
                            </transition>
                            <div class="flex flex-col xl:flex-row w-full">
                                <div class="mt-4 w-full">
                                    <span class="font-normal capitalize">SQL En Proceso</span>
                                    <input v-model="form.sql_in_process_hp_list" class="text-bo-input hidden" />
                                    <div class="mt-1 flex flex-row form-input cursor-pointer p-0 h-10 border-none">
                                        <div class="w-full text-left px-2 focus:outline-none truncate flex items-center" :class="form.sql_in_process_hp_list ? 'text-bo-input' : 'text-gray-500'" @click="selectorToggle('sqlProcess')" ref="sqlProcessNameRef">
                                            {{ selectOptionMsg }}
                                        </div>
                                        <button type="button" @click="clear('sqlProcess', 'sql_in_process_hp_list')" class="focus:outline-none bg-gray-500 hover:bg-gray-300 border text-bo-input rounded-r-md w-10">
                                            <i class="mdi mdi-close text-xl"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <transition name="slide-fade">
                                <div v-if="sqlProcessSelectorExpanded" class="flex flex-row w-full p-2 mt-1 bg-gray-300 rounded rounded-md shadow-md">
                                    <HpListSelector @selection="selection" :prefix="'sqlProcess'" :target="'sql_in_process_hp_list'"></HpListSelector>
                                </div>
                            </transition>
                            <div class="flex flex-col xl:flex-row w-full">
                                <div class="mt-4 w-full">
                                    <span class="font-normal capitalize">SQL</span>
                                    <input v-model="form.sql_hp_list" class="text-bo-input hidden" />
                                    <div class="mt-1 flex flex-row form-input cursor-pointer p-0 h-10 border-none">
                                        <div class="w-full text-left px-2 focus:outline-none truncate flex items-center" :class="form.sql_hp_list ? 'text-bo-input' : 'text-gray-500'" @click="selectorToggle('sql')" ref="sqlNameRef">
                                            {{ selectOptionMsg }}
                                        </div>
                                        <button type="button" @click="clear('sql', 'sql_hp_list')" class="focus:outline-none bg-gray-500 hover:bg-gray-300 border text-bo-input rounded-r-md w-10">
                                            <i class="mdi mdi-close text-xl"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <transition name="slide-fade">
                                <div v-if="sqlSelectorExpanded" class="flex flex-row w-full p-2 mt-1 bg-gray-300 rounded rounded-md shadow-md">
                                    <HpListSelector @selection="selection" :prefix="'sql'" :target="'sql_hp_list'"></HpListSelector>
                                </div>
                            </transition>
                            <div class="flex flex-col xl:flex-row w-full">
                                <div class="mt-4 w-full">
                                    <span class="font-normal capitalize">oportunidades</span>
                                    <input v-model="form.opportunities_hp_list" class="text-bo-input hidden" />
                                    <div class="mt-1 flex flex-row form-input cursor-pointer p-0 h-10 border-none">
                                        <div class="w-full text-left px-2 focus:outline-none truncate flex items-center" :class="form.opportunities_hp_list ? 'text-bo-input' : 'text-gray-500'" @click="selectorToggle('opportunities')" ref="opportunitiesNameRef">
                                            {{ selectOptionMsg }}
                                        </div>
                                        <button type="button" @click="clear('opportunities', 'opportunities_hp_list')" class="focus:outline-none bg-gray-500 hover:bg-gray-300 border text-bo-input rounded-r-md w-10">
                                            <i class="mdi mdi-close text-xl"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <transition name="slide-fade">
                                <div v-if="opportunitiesSelectorExpanded" class="flex flex-row w-full p-2 mt-1 bg-gray-300 rounded rounded-md shadow-md">
                                    <HpListSelector @selection="selection" :prefix="'opportunities'" :target="'opportunities_hp_list'"></HpListSelector>
                                </div>
                            </transition>
                            <div v-show="form.area_has_opportunities_plus" class="flex flex-col xl:flex-row w-full">
                                <div class="mt-4 w-full">
                                    <span class="font-normal capitalize">oportunidades plus</span>
                                    <input v-model="form.opportunities_plus_hp_list" class="text-bo-input hidden" />
                                    <div class="mt-1 flex flex-row form-input cursor-pointer p-0 h-10 border-none">
                                        <div class="w-full text-left px-2 focus:outline-none truncate flex items-center" :class="form.opportunities_plus_hp_list ? 'text-bo-input' : 'text-gray-500'" @click="selectorToggle('opportunitiesPlus')" ref="opportunitiesPlusNameRef">
                                            {{ selectOptionMsg }}
                                        </div>
                                        <button type="button" @click="clear('opportunitiesPlus', 'opportunities_plus_hp_list')" class="focus:outline-none bg-gray-500 hover:bg-gray-300 border text-bo-input rounded-r-md w-10">
                                            <i class="mdi mdi-close text-xl"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <transition name="slide-fade">
                                <div v-if="opportunitiesPlusSelectorExpanded" class="flex flex-row w-full p-2 mt-1 bg-gray-300 rounded rounded-md shadow-md">
                                    <HpListSelector @selection="selection" :prefix="'opportunitiesPlus'" :target="'opportunities_plus_hp_list'"></HpListSelector>
                                </div>
                            </transition>
                            <div class="flex flex-col xl:flex-row w-full">
                                <div class="mt-4 w-full">
                                    <span class="font-normal capitalize">clientes</span>
                                    <input v-model="form.customers_hp_list" class="text-bo-input hidden" />
                                    <div class="mt-1 flex flex-row form-input cursor-pointer p-0 h-10 border-none">
                                        <div class="w-full text-left px-2 focus:outline-none truncate flex items-center" :class="form.customers_hp_list ? 'text-bo-input' : 'text-gray-500'" @click="selectorToggle('customers')" ref="customersNameRef">
                                            {{ selectOptionMsg }}
                                        </div>
                                        <button type="button" @click="clear('customers', 'customers_hp_list')" class="focus:outline-none bg-gray-500 hover:bg-gray-300 border text-bo-input rounded-r-md w-10">
                                            <i class="mdi mdi-close text-xl"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <transition name="slide-fade">
                                <div v-if="customersSelectorExpanded" class="flex flex-row w-full p-2 mt-1 bg-gray-300 rounded rounded-md shadow-md">
                                    <HpListSelector @selection="selection" :prefix="'customers'" :target="'customers_hp_list'"></HpListSelector>
                                </div>
                            </transition>
                            <Request
                                v-if="comEndDateIsPast"
                                model="Edition"
                                action="customersSyncForced"
                                :form="editionToSyncCustomersFilter"
                                @success="onSuccessCustomersSyncForced"
                                @error="onErrorCustomersSyncForced"
                                ref="customersSyncForcedRef"
                                @loading:change="event => (loadingCustomersSync = event)"
                            >
                                <button type="button" @click="customersSyncClick" class="self-end rounded rounded-full shadow-md bg-orange-400 text-blue-900 mt-2 focus:outline-none flex justify-center items-center w-72 h-10">
                                    <i v-if="loadingCustomersSync" class="mdi mdi-autorenew mdi-spin text-blue-cust text-2xl leading-none"></i>
                                    <span v-else>Sincronizar clientes y oportunidades</span>
                                </button>
                            </Request>
                        </div>
                    </div>
                </div>
                <ModalFooter @close="show = false">
                    <Request model="Edition" action="update" :form="form" @success="onSuccessUpdate" @error="onErrorUpdate" ref="updateEditionRef" />
                    <ActionBtn v-show="!editable" :action="'edit'" @edit="edit" />
                    <ActionBtn v-show="editable" :action="'store'" @store="store" />
                </ModalFooter>
            </form>
        </ModalBase>
    </div>
</template>

<script>
    import { actions } from '@/store';
    import { Request } from '@/api/components';
    import ModalBase from '@/pages/inside/shared/modals/ModalBase';
    import ModalHeader from '@/pages/inside/shared/modals/ModalHeader';
    import ActionBtn from '@/pages/inside/shared/modals/ActionBtn';
    import InputNumber from '@/pages/inside/shared/modals/InputNumber';
    import CourseSelector from '@/pages/inside/shared/modals/CourseSelector';
    import HpListSelector from '@/pages/inside/shared/modals/HpListSelector';
    import ModalFooter from '@/pages/inside/shared/modals/ModalFooter';

    export default {
        name: 'Show',
        components: {
            Request,
            ModalBase,
            ModalHeader,
            ModalFooter,
            CourseSelector,
            HpListSelector,
            ActionBtn,
            InputNumber,
        },
        props: ['entity', 'type'],
        data() {
            return {
                show: false,
                editable: false,
                editionFilter: {
                    id: null,
                },
                form: {
                    id: null,
                    name: null,
                    com_start_date: null,
                    com_end_date: null,
                    initial_opportunity_plus_weight: 0.8,
                    final_opportunity_plus_weight: 0.1,
                    opportunities_plus_target: null,
                    customers_target: null,

                    course_id: null,
                    leads_hp_list: null,
                    sql_hp_list: null,
                    sql_in_process_hp_list: null,
                    opportunities_hp_list: null,
                    opportunities_plus_hp_list: null,
                    customers_hp_list: null,
                    area_has_opportunities_plus: false,
                },
                coursesSelectorExpanded: false,
                leadsSelectorExpanded: false,
                sqlSelectorExpanded: false,
                sqlProcessSelectorExpanded: false,
                opportunitiesSelectorExpanded: false,
                opportunitiesPlusSelectorExpanded: false,
                customersSelectorExpanded: false,
                comDates: {
                    start: null,
                    end: null,
                },
                comCalendarExpanded: false,
                loadingCustomersSync: false,
                comEndDateStored: null,
                editionToSyncCustomersFilter: {
                    id: null,
                },
            };
        },
        watch: {
            comDates: {
                handler(n, o) {
                    if (n !== o) {
                        this.form.com_start_date = this.$dayjs(n.start).format('YYYY-MM-DD');
                        this.form.com_end_date = this.$dayjs(n.end).format('YYYY-MM-DD');
                        this.comCalendarToggle();
                    }
                },
                deep: true,
            },
            show(n) {
                if (n) {
                    this.editionFilter.id = this.elSelected.id;
                    if (this.type === 'subModal') {
                        this.editionFilter.id = this.subElSelected.id;
                    }
                    this.$refs.getEditionRef.request();
                    this.$refs.nameRef.disabled = true;
                } else {
                    this.editable = false;
                }
            },
        },
        computed: {
            elSelected() {
                return actions.getElSelected();
            },
            subElSelected() {
                return actions.getSubElSelected();
            },
            comDatesFormatted() {
                return {
                    start: !this.comDates.start ? null : this.$dayjs(this.comDates.start).format('DD-MM-YYYY'),
                    end: !this.comDates.end ? null : this.$dayjs(this.comDates.end).format('DD-MM-YYYY'),
                };
            },
            comEndDateIsPast() {
                return this.$dayjs(this.comEndDateStored).isBefore(this.$dayjs(), 'day');
            },
        },
        methods: {
            edit() {
                this.editable = !this.editable;
                if (this.editable) {
                    this.$refs.nameRef.disabled = false;
                    this.$refs.nameRef.focus();
                }
            },
            store() {
                this.$refs.updateEditionRef.request();
            },
            onErrorUpdate(errors) {
                this.toastErrors(errors);
            },
            onSuccessUpdate(response) {
                this.$emit('refreshDataTable');
                this.response = JSON.parse(JSON.stringify(response));
                this.$notify({ group: 'generic', title: 'Info', text: this.response.data.message, type: 'info' }, this.millis);
                this.show = false;
            },
            clear(prefix, target) {
                if (!this.editable) {
                    this.showModeNotifier();
                    return;
                }
                this.$refs[prefix + 'NameRef'].textContent = this.selectOptionMsg;
                this.form[target] = null;
                if (this[prefix + 'SelectorExpanded']) {
                    this.selectorToggle(prefix + 'Selector');
                }
            },
            selectorToggle(prefix) {
                if (!this.editable) {
                    this.showModeNotifier();
                    return;
                }
                let selectorStatusKeeper = this[prefix + 'SelectorExpanded'];
                this.collapseAllSelectors();
                this[prefix + 'SelectorExpanded'] = !selectorStatusKeeper;
            },
            collapseAllSelectors(prefix) {
                let selectorStatusKeeper = this[prefix + 'SelectorExpanded'];
                this.coursesSelectorExpanded = false;
                this.leadsSelectorExpanded = false;
                this.sqlSelectorExpanded = false;
                this.sqlProcessSelectorExpanded = false;
                this.opportunitiesSelectorExpanded = false;
                this.opportunitiesPlusSelectorExpanded = false;
                this.customersSelectorExpanded = false;
                this[prefix + 'SelectorExpanded'] = selectorStatusKeeper;
            },
            setFromCourseDefaults(course) {
                this.form.course_id = course.id;
                this.form.leads_hp_list = course.default_leads_hp_list;
                this.form.sql_hp_list = course.default_sql_hp_list;
                this.form.sql_in_process_hp_list = course.default_sql_in_process_hp_list;
                this.form.opportunities_hp_list = course.default_opportunities_hp_list;
                this.form.opportunities_plus_hp_list = course.default_opportunities_plus_hp_list;
                this.form.customers_hp_list = course.default_customers_hp_list;
            },
            setListNamesFromCourseDefault(course) {
                this.$refs.leadsNameRef.textContent = this.form.leads_hp_list ? course.default_leads_hp_list_name : this.selectOptionMsg;
                this.$refs.sqlNameRef.textContent = this.form.sql_hp_list ? course.default_sql_hp_list_name : this.selectOptionMsg;
                this.$refs.sqlProcessNameRef.textContent = this.form.sql_in_process_hp_list ? course.default_sql_in_process_hp_list_name : this.selectOptionMsg;
                this.$refs.opportunitiesNameRef.textContent = this.form.opportunities_hp_list ? course.default_opportunities_hp_list_name : this.selectOptionMsg;
                if (course.area_has_opportunities_plus) {
                    this.$refs.opportunitiesPlusNameRef.textContent = this.form.opportunities_plus_hp_list ? course.default_opportunities_plus_hp_list_name : this.selectOptionMsg;
                }
                this.$refs.customersNameRef.textContent = this.form.customers_hp_list ? course.default_customers_hp_list_name : this.selectOptionMsg;
            },
            async selection(prefix, target, el) {
                //if (prefix === 'courses') {
                //    await this.$refs.getAvailFromByCourseRef.request();
                //    this.setFromCourseDefaults(el);
                //    this.setListNamesFromCourseDefault(el);
                //    this.form.area_has_opportunities_plus = el.area_has_opportunities_plus;
                //}
                this.$refs[prefix + 'NameRef'].textContent = el.name;
                this.form[target] = el.id;
                this.selectorToggle(prefix + 'Selector');
            },
            comCalendarToggle() {
                if (!this.editable) {
                    this.showModeNotifier();
                    return;
                }
                this.comCalendarExpanded = !this.comCalendarExpanded;
            },
            decreaseInitialOpWeight() {
                if (!this.editable) {
                    this.showModeNotifier();
                    return;
                }
                if (this.form.initial_opportunity_plus_weight >= 0.1) {
                    let value = parseFloat(this.form.initial_opportunity_plus_weight) - 0.1;
                    this.form.initial_opportunity_plus_weight = value.toFixed(1);
                }
            },
            increaseInitialOpWeight() {
                if (!this.editable) {
                    this.showModeNotifier();
                    return;
                }
                if (this.form.initial_opportunity_plus_weight <= 0.9 && this.form.initial_opportunity_plus_weight < this.form.final_opportunity_plus_weight) {
                    let value = parseFloat(this.form.initial_opportunity_plus_weight) + 0.1;
                    this.form.initial_opportunity_plus_weight = value.toFixed(1);
                }
            },
            decreaseFinalOpWeight() {
                if (!this.editable) {
                    this.showModeNotifier();
                    return;
                }
                if (this.form.final_opportunity_plus_weight >= 0.1 && this.form.initial_opportunity_plus_weight < this.form.final_opportunity_plus_weight) {
                    let value = parseFloat(this.form.final_opportunity_plus_weight) - 0.1;
                    this.form.final_opportunity_plus_weight = value.toFixed(1);
                }
            },
            increaseFinalOpWeight() {
                if (!this.editable) {
                    this.showModeNotifier();
                    return;
                }
                if (this.form.final_opportunity_plus_weight <= 0.9) {
                    let value = parseFloat(this.form.final_opportunity_plus_weight) + 0.1;
                    this.form.final_opportunity_plus_weight = value.toFixed(1);
                }
            },
            onSuccessGetEdition(edition) {
                this.comEndDateStored = edition.com_end_date;
                this.editionToSyncCustomersFilter.id = edition.id;
                this.setForm(edition);
                this.setListNames(edition);
                this.setCalendarRanges(edition);
            },
            onErrorGetEdition(errors) {
                this.toastErrors(errors);
            },
            setForm(edition) {
                this.form.id = edition.id;
                this.form.name = edition.name;
                this.form.com_start_date = edition.com_start_date;
                this.form.com_end_date = edition.com_end_date;
                this.form.initial_opportunity_plus_weight = edition.initial_opportunity_plus_weight;
                this.form.final_opportunity_plus_weight = edition.final_opportunity_plus_weight;
                this.form.opportunities_plus_target = edition.opportunities_plus_target;
                this.form.customers_target = edition.customers_target;
                this.form.course_id = edition.course_id;
                this.form.info_requests_hp_list = edition.info_requests_hp_list;
                this.form.inscriptions_hp_list = edition.inscriptions_hp_list;
                this.form.leads_hp_list = edition.leads_hp_list;
                this.form.sql_hp_list = edition.sql_hp_list;
                this.form.sql_in_process_hp_list = edition.sql_in_process_hp_list;
                this.form.customers_hp_list = edition.customers_hp_list;
                this.form.opportunities_hp_list = edition.opportunities_hp_list;
                this.form.area_has_opportunities_plus = edition.area_has_opportunities_plus;
                this.form.opportunities_plus_hp_list = edition.opportunities_plus_hp_list;
                this.comDates.start = this.$dayjs(edition.com_start_date).format('YYYY-MM-DD');
                this.comDates.end = this.$dayjs(edition.com_end_date).format('YYYY-MM-DD');
            },
            setListNames(edition) {
                this.$refs.coursesNameRef.textContent = edition.course_name;
                this.$refs.leadsNameRef.textContent = this.form.leads_hp_list ? edition.leads_hp_list_name : this.selectOptionMsg;
                this.$refs.sqlNameRef.textContent = this.form.sql_hp_list ? edition.sql_hp_list_name : this.selectOptionMsg;
                this.$refs.sqlProcessNameRef.textContent = this.form.sql_in_process_hp_list ? edition.sql_in_process_hp_list_name : this.selectOptionMsg;
                this.$refs.opportunitiesNameRef.textContent = this.form.opportunities_hp_list ? edition.opportunities_hp_list_name : this.selectOptionMsg;
                if (edition.area_has_opportunities_plus) {
                    this.$refs.opportunitiesPlusNameRef.textContent = this.form.opportunities_plus_hp_list ? edition.opportunities_plus_hp_list_name : this.selectOptionMsg;
                }
                this.$refs.customersNameRef.textContent = this.form.customers_hp_list ? edition.customers_hp_list_name : this.selectOptionMsg;
            },
            setCalendarRanges(edition) {
                this.comDates.start = edition.com_start_date;
                this.comDates.end = edition.com_end_date;
            },
            customersSyncClick() {
                if (!this.editable) {
                    this.showModeNotifier();
                    return;
                }
                this.$refs.customersSyncForcedRef.request();
            },
            onSuccessCustomersSyncForced(response) {
                this.$emit('refreshDataTable');
                this.$notify({ group: 'generic', title: 'Info', text: response.data.message, type: 'info' }, this.millis);
                this.show = false;
            },
            onErrorCustomersSyncForced(errors) {
                this.toastErrors(errors);
            },
        },
    };
</script>

<style scoped></style>
