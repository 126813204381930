var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex h-full justify-center items-center"},[(_vm.fullRow.area_has_opportunities_plus)?_c('div',{staticClass:"p-1 rounded-full w-14 h-14 border-4 flex justify-center items-center",class:{
                'border-opacity-100': _vm.fullRow.com_remaining_days < 30,
                'border-opacity-0': _vm.fullRow.com_remaining_days > 30,
                'border-bad': _vm.fullRow.com_light === 'bad',
                'border-regular': _vm.fullRow.com_light === 'regular',
                'border-good': _vm.fullRow.com_light === 'good'
                }},[_vm._v(" "+_vm._s(_vm._f("numberFormat")(_vm.fullRow.customers_target_percent_reached,0))+"% ")]):_vm._e(),(!_vm.fullRow.area_has_opportunities_plus && _vm.fullRow.customers_target_percent_reached || !_vm.fullRow.area_has_opportunities_plus && _vm.fullRow.customers_target_percent_reached === 0)?_c('div',{staticClass:"p-1 rounded-full w-14 h-14 border-4 flex justify-center items-center",class:{
                    'border-opacity-100': _vm.fullRow.customers_target_percent_reached === 0,
                    'border-bad': _vm.fullRow.com_light === 'bad',
                    'border-regular': _vm.fullRow.com_light === 'regular',
                    'border-good': _vm.fullRow.com_light === 'good'
                }},[_vm._v(" "+_vm._s(_vm._f("numberFormat")(_vm.fullRow.customers_target_percent_reached,0))+"% ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }