<template>
    <All model="Course" :immediate="true" v-slot="{data:courses}" :query="coursesFilter"
         @loading:change="(event) => loading = event" ref="coursesRef">
        <div class="flex flex-col w-full justfy-center items-end text-bo-input">
            <div class="flex flex-row w-full">
                <div class="w-1/3 flex justify-center sm:justify-center">
                </div>
                <div class="w-2/3">
                    <searcher @changeText="changeText"/>
                </div>
            </div>
            <CourseSubTable :data="courses.data" :fields="fields" :loading="loading" :page="coursesFilter.page" @selectRow="selectRow" class="mt-2"/>
            <div class="w-full flex flex-row flex-wrap justify-end items-center pt-1">
                <PaginateTextInfo v-if="courses.meta" :meta="courses.meta" class="flex-auto text-center"/>
<!--                <PaginateLinks v-if="courses.meta" :meta="courses.meta" @changePage="changePage" class="hidden md:flex"/>-->
                <PaginateMobile v-if="courses.links" :links="courses.links" @changePage="changePage"/>
            </div>
        </div>
    </All>
</template>

<script>
import All from "@/api/components/All";
import CourseSubTable from "@/pages/inside/shared/datatables/CourseSubTable";
import Searcher from "@/pages/inside/shared/datatables/Searcher";
import PaginateMobile from "@/pages/inside/shared/datatables/PaginateMobile";
import PaginateTextInfo from "@/pages/inside/shared/datatables/PaginateTextInfo";
export default {
    name: "CourseSelector",
    props: ['prefix', 'target'],
    components: {
        All, CourseSubTable, Searcher, PaginateMobile, PaginateTextInfo
    },
    data() {
        return {
            loading: false,
            coursesFilter: {
                scope: 'com',
                page: null,
                perPage: 10,
                field: 'name',
                direction: 'asc',
                text: null
            },
            el: null,
            fields,
        }
    },
    methods: {
        changePage(page){
            this.coursesFilter.page = page;
            this.$refs.coursesRef.request(); // Para hacer la request
        },
        changeText(text){
            this.coursesFilter.page = null;
            this.coursesFilter.text = text;
            this.$refs.coursesRef.request(); // Para hacer la request
        },
        selectRow(el) {
            this.el = el;
            this.$emit('selection', this.prefix, this.target, this.el);
        },
    }
};

const fields = [
    {
        label: 'Curso',
        key: 'name'
    },
];
</script>

<style scoped>

</style>