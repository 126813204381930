<template>
    <div class="flex h-full justify-center items-center">
        <div v-if="fullRow.area_has_opportunities_plus"
             class="p-1 rounded-full w-14 h-14 border-4 flex justify-center items-center"
             :class="{
                    'border-opacity-100': fullRow.com_remaining_days < 30,
                    'border-opacity-0': fullRow.com_remaining_days > 30,
                    'border-bad': fullRow.com_light === 'bad',
                    'border-regular': fullRow.com_light === 'regular',
                    'border-good': fullRow.com_light === 'good'
                    }">
            {{ fullRow.customers_target_percent_reached | numberFormat(0) }}%
        </div>
        <div v-if="!fullRow.area_has_opportunities_plus && fullRow.customers_target_percent_reached || !fullRow.area_has_opportunities_plus && fullRow.customers_target_percent_reached === 0"
             class="p-1 rounded-full w-14 h-14 border-4 flex justify-center items-center"
             :class="{
                        'border-opacity-100': fullRow.customers_target_percent_reached === 0,
                        'border-bad': fullRow.com_light === 'bad',
                        'border-regular': fullRow.com_light === 'regular',
                        'border-good': fullRow.com_light === 'good'
                    }">
            {{ fullRow.customers_target_percent_reached | numberFormat(0) }}%
        </div>
    </div>
</template>

<script>
export default {
    name: "CellCustomersTargetReached",
    props: ['fullRow']
}
</script>

<style scoped>

</style>